import { Register } from 'types/auth';
import { ResponseData } from 'types/common/response';
import axios from 'utils/axios';

export const postRegister = async (body: Register): Promise<ResponseData<any>> => {
  return await axios.post(`/api/v1/register/request`, body);
};

export const reInvitationRegister = async (body: any): Promise<ResponseData<any>> => {
  return await axios.post(`/api/v1/authentication/re-invitation`, body);
};

export const reInvitationRegisterSession = async (token: string): Promise<ResponseData<any>> => {
  return await axios.post(
    `/api/v1/register/re-invitation`,
    {},
    {
      headers: {
        Authorization: `Bearer ${token}`
      }
    }
  );
};

export const postAccept = async (token: string): Promise<ResponseData<any>> => {
  return await axios.post(
    `/api/v1/register/accept`,
    {},
    {
      headers: {
        Authorization: `Bearer ${token}`
      }
    }
  );
};
