import { FormInputOption } from 'types/common/form';

import * as yup from 'yup';

export const validateSchema = (options: FormInputOption[]) => {
  const schema = Object();
  for (let form of options) {
    if (form.type === 'checkbox-list') {
      schema[form.name] = yup.array();
    } else if (form.type !== 'number') {
      schema[form.name] = yup.string();
    } else {
      schema[form.name] = yup.number().typeError('กรุณากรอกตัวเลข');
    }

    if (form.type === 'file-image') {
      schema[form.name] = yup.mixed().required('Avatar is a required.');
    }

    if (form.type === 'file-pdf') {
      schema[form.name] = yup.mixed();
    }

    if (form.type === 'element') {
      schema[form.name] = yup.mixed();
    }

    if (form.type === 'select-chips') {
      schema[form.name] = yup.array();
    }

    if (form.type === 'email') {
      const EMAIL_REGX =
        // /^(([^=+$%#\/<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".*"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        // /^(([^=\-+$%#\/<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        /^(([^=\-$%#\/<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

      schema[form.name] = yup.string().matches(EMAIL_REGX, 'กรุณากรอก email ให้ถูกต้อง');
      // .email('กรุณากรอก email ให้ถูกต้อง')
      // .test(`err-email-text-${form.name}`, `กรุณากรอก${form.label}ให้ถูกต้อง`, (val: string) => {
      //   if (!val) return true; // allow empty value
      //   return regex.test(val);
      // });
    }

    if (form.type === 'password' && form.validation_match) {
      schema[form.name] = yup
        .string()
        .max(255)
        .min(6, 'อักขระอย่างน้อย 6 ตัว')
        .required(`กรุณาใส่ข้อมูล ${form.label || form.name}`)
        .oneOf([yup.ref(form.validation_match)], 'กรุณากรอกรหัสผ่านให้ตรงกัน');
    }

    if (form.type === 'date' && form.required) {
      schema[form.name] = yup.date().typeError(`กรุณาใส่ข้อมูล ${form.label || form.name}`);
    }

    if (form.type === 'date-range' && form.required) {
      schema['start_date'] = yup.date().typeError(`กรุณาใส่ข้อมูล ${form.label || form.name}`);
      schema['end_date'] = yup.date().typeError(`กรุณาใส่ข้อมูล ${form.label || form.name}`);
    }

    if (form.minLength === form.maxLength && form.minLength !== undefined && form.maxLength !== undefined) {
      schema[form.name] = schema[form.name].test(
        `err-min-length-${form.name}`,
        `กรุณากรอก ${form.label} ตัวเลข ${form.minLength} หลัก`,
        (val: string) => {
          if (!val) return true;
          if (form?.minLength && form?.maxLength) {
            const is = `${val}`.length >= form?.minLength && `${val}`.length <= form?.maxLength;
            return is;
          }
          return false;
        }
      );
    }

    if (form.minLength) {
      schema[form.name] = schema[form.name].test(
        `err-min-length-${form.name}`,
        `กรุณากรอก  ${form.label} เป็นตัวเลขอย่างน้อย ${form.minLength} ตัว`,
        (val: string) => {
          if (!val) return true;
          if (form?.minLength) {
            const is = `${val}`.length >= form?.minLength;
            return is;
          }
          return false;
        }
      );
    }
    if (form.maxLength && form.type !== 'file-images' && form.type !== 'file-images-v2') {
      schema[form.name] = schema[form.name].test(
        `err-max-length-${form.name}`,
        `กรุณากรอก  ${form.label} ตัวเลขที่ไม่เกิน 
        ${form.maxLength} ตัว`,
        (val: string) => {
          if (!val) return true;
          if (form?.maxLength) {
            const is = `${val}`.length <= form?.maxLength;
            return is;
          }
          return false;
        }
      );
    }

    if (form.regex && form.regex.check) {
      if (form.regex.title === 'normal') {
        const thaiAndEnglishRegex = /[\u0E00-\u0E7F]|[a-zA-Z]/;
        schema[form.name] = schema[form.name].test(
          `err-thai-and-english-${form.name}`,
          `กรุณากรอก${form.label}ให้ถูกต้อง`,
          (val: string) => {
            if (!val) return true; // allow empty value
            return thaiAndEnglishRegex.test(val);
          }
        );
      } else if (form.regex.title === 'thai') {
        const thaiAndEnglishRegex = /[\u0E00-\u0E7F]/;
        schema[form.name] = schema[form.name].test(`err-thai-${form.name}`, `กรุณากรอก${form.label}ให้ถูกต้อง`, (val: string) => {
          if (!val) return true; // allow empty value
          return thaiAndEnglishRegex.test(val);
        });
      } else if (form.regex.title === 'special') {
        const regex = /^[a-zA-Z0-9\u0E00-\u0E7F ]+$/g;
        schema[form.name] = schema[form.name].test(`err-special-text-${form.name}`, `กรุณากรอก${form.label}ให้ถูกต้อง`, (val: string) => {
          if (!val) return true; // allow empty value
          return regex.test(val);
        });
      } else if (form.regex.title === 'special-address') {
        const regex = /^[0-9\s/]+$/g;
        schema[form.name] = schema[form.name].test(
          `err-special-address-text-${form.name}`,
          `กรุณากรอก${form.label}ให้ถูกต้อง`,
          (val: string) => {
            if (!val) return true; // allow empty value
            return regex.test(val);
          }
        );
      } else if (form.regex.title === 'number') {
        const regex = /[0-9]$/;
        schema[form.name] = schema[form.name].test(`err-number-text-${form.name}`, `กรุณากรอก${form.label}ให้ถูกต้อง`, (val: string) => {
          if (!val) return true; // allow empty value
          return regex.test(val);
        });
      } else if (form.regex.title === 'float') {
        const regex = /^[+-]?\d+(\.\d+)?$/;
        schema[form.name] = schema[form.name].test(`err-float-text-${form.name}`, `กรุณากรอก${form.label}ให้ถูกต้อง`, (val: string) => {
          if (!val) return true; // allow empty value
          return regex.test(val);
        });
      } else if (form.regex.title === 'eng-number') {
        const numberAndEnglishRegex = /[0-9]|[a-zA-Z]/;
        schema[form.name] = schema[form.name].test(
          `err-number-and-english-${form.name}`,
          `กรุณากรอก${form.label}ให้ถูกต้อง`,
          (val: string) => {
            if (!val) return true; // allow empty value
            return numberAndEnglishRegex.test(val);
          }
        );
      } else if (form.regex.title === 'number-special') {
        const pattern = /^([0-9]+|\d{1,}-\d{1,})$/;
        schema[form.name] = schema[form.name].test(`err-number-text-${form.name}`, `กรุณากรอก${form.label}ให้ถูกต้อง`, (val: string) => {
          if (!val) return true; // allow empty value
          return pattern.test(val);
        });
      }
    }

    if (form.required) {
      if (!form.hidden) {
        if (form.type === 'checkbox') schema[form.name] = yup.bool().oneOf([true], 'กรุณาเลือกอย่างน้อย 1');
        else schema[form.name] = schema[form.name].required(`กรุณาใส่ข้อมูล ${form.label || form.name}`);
      }
    }

    if (form.test) {
      schema[form.name] = schema[form.name]
        .test(`err-${form.name}`, form.errorMessage, form.test)
        .required(`กรุณาใส่ข้อมูล ${form.label || form.name}`);
    }
  }

  return schema;
};

export const validateForm = (options: FormInputOption[]) => {
  return yup.object(validateSchema(options));
};

export const handleInitialValues = (options: FormInputOption[]) => {
  const values = Object();
  for (let form of options) {
    if (form.type == 'checkbox') values[form.name] = form.value || [];
    else if (form.type == 'checkbox-one') {
      values[form.name] = form.value || false;
    } else if (form.type == 'switch') {
      values[form.name] = form.value || false;
    } else if (form.type == 'date') {
      values[form.name] = form.value || '';
    } else if (form.type == 'date-filter') {
      values[form.name] = form.value || '';
    } else if (form.type == 'date-range') {
      if (Array.isArray(form.value)) {
        values['start_date'] = form.value[0];
        values['end_date'] = form.value[1];
      } else {
        values['start_date'] = '';
        values['end_date'] = '';
      }
    } else if (form.type == 'date-range-more' || form.type == 'date-range-filter') {
      if (Array.isArray(form.value)) {
        values[form.name + 'start_date'] = form.value[0];
        values[form.name + 'end_date'] = form.value[1];
      } else {
        values[form.name + 'start_date'] = '';
        values[form.name + 'end_date'] = '';
      }
    } else values[form.name] = form.value || '';
  }
  return values;
};
