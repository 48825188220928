import { Box, Typography, Button } from '@mui/material';
import AuthBackground from '../AuthBackground';
import { reInvitationRegister } from 'api/register';
import DialogSuccess from 'templates/components/DialogSuccess';
import { useCallback, useState } from 'react';

const UnverifiedEmail = ({ email, password }: any) => {
  const [openModal, setOpenModal] = useState(false);

  const handleSubmitModal = useCallback(async ({ submit, id, values }: any) => {
    setOpenModal(false);
    window.location.reload();
  }, []);

  const handleReInvitation = async () => {
    const response: any = await reInvitationRegister({ identity_id: email, password: password });
    if (response.code === 200) {
      setOpenModal(true);
    }
  };
  return (
    <>
      <Box sx={{ minHeight: '100vh' }}>
        <AuthBackground />

        <Box
          sx={{
            mt: -2,
            height: '100vh',
            display: 'flex',
            justifyContent: 'center',
            alignContent: 'center',
            alignItems: 'center',
            flexDirection: 'column'
          }}
        >
          <svg width="338" height="338" viewBox="0 0 438 438" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M219 237.25L73 146V328.5H237.25V365H73C62.9625 365 54.3728 361.429 47.231 354.287C40.0892 347.145 36.5122 338.55 36.5 328.5V109.5C36.5 99.4625 40.077 90.8728 47.231 83.731C54.385 76.5892 62.9747 73.0122 73 73H365C375.038 73 383.633 76.577 390.787 83.731C397.941 90.885 401.512 99.4747 401.5 109.5V237.25H365V146L219 237.25ZM219 200.75L365 109.5H73L219 200.75ZM346.75 419.75L321.2 394.2L349.944 365H273.75V328.5H349.944L320.744 299.3L346.75 273.75L419.75 346.75L346.75 419.75ZM73 146V346.75V237.25V238.619V109.5V146Z"
              fill="#007BB5"
            />
          </svg>
          <Typography variant="h4" component="h1" sx={{ marginTop: 2, fontWeight: 'bold' }}>
            ขออภัยค่ะ
          </Typography>

          <Typography variant="h5" sx={{ marginTop: 2, color: '#666', textAlign: 'center' }}>
            กรุณายืนยันตัวตน <br />
            คุณยังไม่ได้ทำการยืนยันตัวตนผ่านอีเมล <br />
            กรุณาตรวจสอบคำเชิญในอีเมล และยืนยันการใช้งาน
          </Typography>

          <Button variant="contained" color="primary" size="large" sx={{ marginTop: 4 }} onClick={handleReInvitation}>
            กดเพื่อยอมรับ link ยืนยันตัวตนอีกครั้ง
          </Button>
        </Box>
      </Box>
      <DialogSuccess
        title={'ยืนยันตัวตนสำเร็จ'}
        text={'ผลการยืนยันตัวตนผ่านอีเมลเสร็จสิ้น !'}
        open={openModal}
        handleSubmit={handleSubmitModal}
      ></DialogSuccess>
    </>
  );
};

export default UnverifiedEmail;
