// import { WarningOutlined } from '@ant-design/icons';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, Typography } from '@mui/material';
import { useDataTable } from 'templates/hook/useDataTable';
import { ReturnModel } from 'types/common/table';

type Iprop = {
  title?: any;
  text?: any;
  open: boolean;
  handleSubmit?: (data: ReturnModel) => Promise<void> | void;
};

export default function DialogSuccess({ title, text, open, handleSubmit }: Iprop) {
  const { handleRefresh } = useDataTable();

  const handleCloseFunc = async () => {
    handleSubmit &&
      (await handleSubmit({
        submit: true
      }));
    handleRefresh();
  };

  return (
    <>
      <div>
        <Dialog
          open={open}
          keepMounted
          onClose={handleCloseFunc}
          sx={{
            '& .MuiDialog-container': {
              '& .MuiPaper-root': {
                width: '100%',
                maxWidth: '450px',
                minWidth: '450px'
              }
            }
          }}
        >
          <DialogTitle>
            <Box sx={{ width: '100%', alignItems: 'center', display: 'flex', justifyContent: 'flex-start', marginLeft: '-8px' }}>
              {/* <WarningOutlined rev style={{ fontSize: '30px', color: 'red', margin: 10 }} /> */}
              <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M13.5001 30.63L4.18506 21.315L8.43006 17.07L13.5001 22.155L28.3201 7.32001L32.5651 11.565L13.5001 30.63Z"
                  fill="#4C9F70"
                />
              </svg>

              <Typography variant="h5" sx={{ marginLeft: '10px', color: '#008865' }}>
                {title ?? 'ดำเนินการสำเร็จ'}
              </Typography>
            </Box>
          </DialogTitle>
          <DialogContent sx={{ textAlign: 'left' }}>
            <DialogContentText sx={{ textAlign: 'left', marginLeft: '0px' }}>
              {/* <Stack spacing={2} sx={{textAlign: 'left'}}>
              <Item sx={{textAlign: 'left'}}> */}
              <Typography>{text ?? 'บันทึกข้อมูลสำเร็จ'}</Typography>
              {/* </Item>
            </Stack> */}
            </DialogContentText>
          </DialogContent>
          <DialogActions sx={{ textAlign: 'center', justifyContent: 'center' }}>
            <Grid container>
              <Grid item xs={6}></Grid>
              <Grid item xs={6} pr={1} pb={1}>
                <Grid container spacing={1.5}>
                  <Grid item xs={6}></Grid>
                  <Grid item xs={6}>
                    <Button fullWidth variant={'contained'} color="success" onClick={handleCloseFunc}>
                      เสร็จสิ้น
                    </Button>
                  </Grid>
                  {/* <Grid item xs={6}>
                  <LoadingButton fullWidth loading={load} variant="contained" loadingPosition="start" onClick={handleSubmitFunc}>
                    ยืนยัน
                  </LoadingButton>
                </Grid> */}
                </Grid>
              </Grid>
            </Grid>
          </DialogActions>
        </Dialog>
      </div>
    </>
  );
}
