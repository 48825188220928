import { ReactElement, useState } from 'react';

// next
import { NextPageContext } from 'next';
import { getCsrfToken, getProviders } from 'next-auth/react';

// material-ui

// project import
import Page from 'components/Page';
import Layout from 'layout';
import AuthWrapper from 'sections/auth/AuthWrapper';
import AuthLogin from 'sections/auth/auth-forms/AuthLogin';
import UnverifiedEmail from 'sections/auth/auth-forms/UnVerifiedEmail';

export default function SignIn({ providers, csrfToken }: any) {
  const [showUnverifiedEmail, setShowUnverifiedEmail] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  return (
    <Page title="Login">
      {showUnverifiedEmail ? (
        <UnverifiedEmail email={email} password={password}></UnverifiedEmail>
      ) : (
        <AuthWrapper>
          <AuthLogin
            providers={providers}
            csrfToken={csrfToken}
            setShowUnverifiedEmail={setShowUnverifiedEmail}
            setEmail={setEmail}
            setPassword={setPassword}
          />
        </AuthWrapper>
      )}
    </Page>
  );
}

SignIn.getLayout = function getLayout(page: ReactElement) {
  return <Layout variant="auth">{page}</Layout>;
};

export async function getServerSideProps(context: NextPageContext) {
  const providers = await getProviders();
  const csrfToken = await getCsrfToken(context);

  return {
    props: { providers, csrfToken }
  };
}
