import axios, { AxiosResponse } from 'axios';
import { BASE_API } from 'config';
import { join } from 'lodash';
import { getSession } from 'next-auth/react';

const onSuccessRequest = (response: AxiosResponse) => response.data;
const onRejectRequest = (error: any) => Promise.reject(error);

// ==============================|| AXIOS - FOR MOCK SERVICES ||============================== //

axios.interceptors.request.use(async (config) => {
  const session = await getSession();

  if (session && session.id) {
    const headers = {
      Authorization: `Bearer ${session?.id}`
    };
    config.headers = { ...config.headers, ...headers };
  }

  if (!config.url?.startsWith('http') && !config.url?.includes(BASE_API)) {
    config.url = join([BASE_API, config.url], '');
  }

  return config;
});

axios.interceptors.response.use(onSuccessRequest, onRejectRequest);

export default axios;
